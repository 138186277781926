// extracted by mini-css-extract-plugin
export var maineDigest = "maine_digest_2022_01-module--maineDigest--kYMDq";
export var heroImage = "maine_digest_2022_01-module--heroImage--PqJON";
export var columnWrapper = "maine_digest_2022_01-module--columnWrapper--l5KFC";
export var headingLogo = "maine_digest_2022_01-module--headingLogo--sOrCg";
export var headingWrapper = "maine_digest_2022_01-module--headingWrapper--gQpGa";
export var heading = "maine_digest_2022_01-module--heading--Dd2mS";
export var instance = "maine_digest_2022_01-module--instance--g0BIe";
export var subheading = "maine_digest_2022_01-module--subheading--m4+V4";
export var textWrapper = "maine_digest_2022_01-module--textWrapper--khqh0";
export var latestnewsarticleheadline = "maine_digest_2022_01-module--latestnewsarticleheadline--MD4+k";
export var dateline = "maine_digest_2022_01-module--dateline--RwuxX";
export var whatsNew = "maine_digest_2022_01-module--whatsNew--Fp9R6";
export var photosWrapper = "maine_digest_2022_01-module--photosWrapper--1+Lbo";
export var photo = "maine_digest_2022_01-module--photo--No3pH";
export var photoCaption = "maine_digest_2022_01-module--photoCaption--bpN21";
export var rightPhotoCaption = "maine_digest_2022_01-module--rightPhotoCaption--GLBlU";
export var rightPhoto = "maine_digest_2022_01-module--rightPhoto--7bueT";
export var rightCaptions = "maine_digest_2022_01-module--rightCaptions--5D3tP";
export var afterCaption = "maine_digest_2022_01-module--afterCaption--jX3SP";